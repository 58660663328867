import React from 'react'
import styles from './FormElements.module.scss'

export const Input = props => {

  const renderError = () => {
    if (props.error) {
      return (
        <span className={styles.error}><i className={`fas fa-exclamation-triangle ${styles.errorIcon}`}></i>{props.error}</span>
      )
    }
  }

  const classes = `${styles.input} ${props.className}`
  const groupclasses = `${styles.group} ${props.groupClassName} ${props.last ? styles.last : ''}`
  const type = props.type ? props.type : 'text'
  const placeholder = props.placeholder ? props.placeholder : ''
  const disabled = props.disabled ? true : false

  return (
    <div className={groupclasses}>
      <input id={props.id} type={type} disabled={disabled} placeholder={placeholder} value={props.value} defaultValue={props.defaultValue} onChange={props.onChange} className={classes} />
      {renderError()}
    </div>
  )
}

export const Textarea = props => {

  const renderError = () => {
    if (props.error) {
      return (
        <span className={styles.error}><i className={`fas fa-exclamation-triangle ${styles.errorIcon}`}></i>{props.error}</span>
      )
    }
  }

  const classes = `${styles.textarea} ${props.className}`
  const groupclasses = `${styles.group} ${props.groupClassName} ${props.last ? styles.last : ''}`
  const placeholder = props.placeholder ? props.placeholder : ''
  const disabled = props.disabled ? true : false

  return (
    <div className={groupclasses}>
      <textarea id={props.id} disabled={disabled} placeholder={placeholder} defaultValue={props.defaultValue} onChange={props.onChange} className={classes}>{props.children}</textarea>
      {renderError()}
    </div>
  )
}

export const Select = props => {

  const renderError = () => {
    if (props.error) {
      return (
        <span className={styles.error}><i className={`fas fa-exclamation-triangle ${styles.errorIcon}`}></i>{props.error}</span>
      )
    }
  }

  const classes = `${styles.select} ${props.className}`
  const groupclasses = `${styles.group} ${props.groupClassName} ${props.last ? styles.last : ''}`
  const disabled = props.disabled ? true : false

  return (
    <div className={groupclasses}>
      <select id={props.id} value={props.value} disabled={disabled} onChange={props.onChange} className={classes}>
        {props.children}
      </select>
      {renderError()}
    </div>
  )
}
