import React, {Component} from 'react'
import styles from './Card.module.scss'

class Card extends Component {


  render() {
    let classes = `${styles.card} ${this.props.className}`
    if (this.props.hasPadding) classes = `${classes} ${styles.hasPadding}`


    return (
      <div 
        className={classes}
        id={this.props.id}
      >
        {this.props.children}
      </div>
    )
  }
}


export default Card