import * as types from '../actions/types'

const initialState = {
  affid: 0,
  beneficios: 0,
  suscriptores: 0,
  listaTransacciones: [],
  listaMeses: [{month: new Date().getMonth()+1, year: new Date().getFullYear()}],
  loading: false,
  error: false
}

const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_AFFILIATE_SUBS:
    return  {...initialState, isLoading: true}
  case types.GET_AFFILIATE_SUBS_SUCCESS:
    return  {
      ...initialState,
      affid: action.payload.affid, 
      beneficios: action.payload.beneficios, 
      suscriptores: action.payload.suscriptores, 
      listaMeses: action.payload.listaMeses,
      listaTransacciones: action.payload.listaTransacciones
    }
  case types.GET_AFFILIATE_SUBS_FAIL:
    return  {...initialState, error: true}
  case types.RESET_BEATS:
    return {...initialState}
  default: 
    return state
  }
}

export default affiliateReducer