import * as types from '../actions/types'

const initialState = {
  feed:[],
  beats: [],
  isLoading: false,
  error: false,
}

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_USER_FEED:
    return  {...initialState, isLoading: true}
  case types.GET_USER_FEED_SUCCESS:
    return  {...initialState, feed: action.payload.feed, beats: action.payload.beats}
  case types.GET_USER_FEED_FAIL:
    return  {...initialState, error: true}
  case types.SET_USER_FEED_LIKE_SUCCESS:
    return  {
      ...state, 
      feed:state.feed.map(f => f.id === action.payload.feedid ? 
        {
          ...f, 
          liked: !f.liked, 
          likes: f.liked === false ? f.likes+1 : f.likes-1
        } 
        : f)}
  case types.LOAD_USER_BEAT:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: true} : b)}
  case types.LOAD_USER_BEAT_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: false} : b)}
  case types.LOAD_USER_BEAT_UPGRADE:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isUpLoading: true} : b)}
  case types.LOAD_USER_BEAT_UPGRADE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isUpLoading: false, subscription: 'vip'} : b)}
  case types.SET_USER_BEAT_FAVOURITE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, favourite: !b.favourite} : b)}
  case types.SET_USER_BEAT_PURCHASE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, purchased: !b.purchased, isLoading:false} : b)}
  case types.RESET_FEED:
    return {...initialState}
  default: 
    return state
  }
}

export default feedReducer