import React, {useState} from 'react'
import styles from './Accordion.module.scss'

const Accordion = (props) => {
  const [isOpen, setOpen] = useState(false)
  const titleStyle = `${styles.title} ${props.dark ? 'bg-b4s-blue-1' : props.light ? 'bg-transparent border border-white' : 'bg-b4s-blue-2'}`
  const contentStyle = `${styles.content} p-5 font-light text-sm ${props.dark ? 'bg-b4s-blue-1' : ''} ${props.light ? 'bg-transparent border-x border-b border-white' : ''}`

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <div className={`mb-5 ${props.className}`}>
      <div
        className={titleStyle}
        onClick={() => setOpen(!isOpen)}
      >
        <div className={styles.titleText}><h3>{props.title}</h3></div>
        <div className={styles.expand}>{isOpen ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}</div>
      </div>
      {isOpen && (
        <div className={contentStyle}>
          {props.children}
        </div>
      )}
    </div>
  )
}

export default Accordion
