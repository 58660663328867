import * as types from '../actions/types'

const initialState = {
  isEuropean: null,
  cookieConsent: null
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.CHECK_GDPR_REQUIRED_TRUE:
    return  {...state, isEuropean: true}
  case types.CHECK_GDPR_REQUIRED_FALSE:
    return  {...state, isEuropean: false, cookieConsent: true}
  case types.COOKIE_CONSENT_TRUE:
    return  {...state, cookieConsent: true}
  case types.COOKIE_CONSENT_FALSE:
    return  {...state, cookieConsent: false}
  case types.RESET_VISITOR:
    return  {...initialState}
  default: 
    return state
  }
}

export default appReducer
