import React from 'react'
import {Navigate} from 'react-router'
import {useSelector} from 'react-redux'
import config from '../config'

const AuthRoute = ({
  children,
  roles,
}) => {
  const {role} = useSelector(state => ({
    role: state.user.role
  }))  
    
  if (config.roles[role] && roles.includes(role)) {
    return  children
  } else if (config.roles[role]) {
    return (
      <Navigate
        to={{
          pathname: config.roles[role].defaultRoute,
        }}
        replace
      />)
  } else {
    return (
      <Navigate
        to={{
          pathname: config.roles['guest'].defaultRoute,
        }}
        replace
      />)
  }
}

export default AuthRoute
