import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import Button from '../Button'
import styles from './CookieConsent.module.scss'
import {giveConsent, denyConsent} from '../../actions/visitor_actions'

const CookieConsent = () => {
  const [moreInfo, wantMoreInfo] = useState(false)
  const dispatch = useDispatch()


  const {cookieConsent} = useSelector(state => (
    {
      cookieConsent: state.visitor.cookieConsent
    }
  ))

  //Si ya tenemos una decisión, no mostrar más
  if (typeof cookieConsent === 'boolean') {
    return ''
  }

  const firstLayer = () => (
    <div className={styles.cookies}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h3>
            <FormattedMessage 
              id="components.cookies.title" 
              defaultMessage="Política de RGDP"
            />
          </h3>
        </div>
        <div className={styles.twoRows}>
          <div className={styles.content}>
            <p>
              <FormattedMessage 
                id="components.cookies.text" 
                defaultMessage="Queremos ofrecerte gran contenido, pero necesitamos saber como usas la página para poder mejorarla. 
                Si nos autorizas a seguir tus movimientos, tenemos acceso a analíticas que nos ayudan a entender que áreas de la página pueden causar confusión.
                Por supuesto, siempre podrás enviarnos tu feedback por el formulario de contacto, estamos encantados de recibir tu opinión sobre nuestra página."
              />
            </p>
          </div>
          <div className={styles.actions}>
            <Button onClick={() => dispatch(giveConsent())}>
              <FormattedMessage 
                id="components.cookies.button.accept" 
                defaultMessage="Acepto"
              />
            </Button>
            <Button dark onClick={() => wantMoreInfo(true)}>
              <FormattedMessage 
                id="components.cookies.button.more" 
                defaultMessage="Más Información"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const secondLayer = () => (
    <div className={styles.cookies}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h3>
            <FormattedMessage 
              id="components.cookies.title" 
              defaultMessage="Política de RGDP"
            /></h3>
        </div>
        <div className={styles.twoRows}>
          <div className={styles.content}>
            <h4>
              <FormattedMessage 
                id="components.cookies.facebook.title" 
                defaultMessage="Pixel de Facebook"
              /></h4>
            <p>
              <FormattedMessage 
                id="components.cookies.facebook.text" 
                defaultMessage="El pixel de facebook nos ayuda a seguir movimientos de visitantes, seguiremos específicamente los pasos del registro, para sacar conclusiones sobre los visitantes que deciden no suscribirse durante el proceso del registro, con el fin de mejorar nuestro proceso. {br} {br}
            El pixel también nos ayudará en limitadas ocasiones a recopilar datos del visitante con el único fin de mostrar nuestra publicidad a visitantes con intereses similares a los que se han suscrito a la página. No nos será posible identificar personas individuales que visitan nuestra página."
                values={{br: <br />}}
              />
            </p>
        
            <h4>
              <FormattedMessage 
                id="components.cookies.hotjar.title" 
                defaultMessage="Hotjar"
              />
            </h4>
            <p>
              <FormattedMessage 
                id="components.cookies.hotjar.text" 
                defaultMessage="Hotjar es un servicio que graba sesiones de visitante, siguiendo los movimientos del ratón y el scroll. Esto ocurre de forma anónima: Tendremos acceso a tu resolución y versión de navegador, tu sistema operativo, pero no tu IP. Vemos tus movimientos, pero no podemos ver los campos de texto, por lo que nos es imposible ver los datos de tu tarjeta de crédito, por ejemplo. <br /> <br />
            Nosotros usamos hotjar para ver comportamientos de usuarios, por ejemplo si nadie navega hacia el final de la portada, podemos sacar la conclusión de que tenemos que hacer una portada más pequeña. Solo usamos los datos para mejorar la experiencia en la página e identificar que áreas de la página necesitan mejora."
              />
            </p>
        
            <h4>
              <FormattedMessage 
                id="components.cookies.metricool.title" 
                defaultMessage="Metricool"
              />
            </h4>
            <p>
              <FormattedMessage 
                id="components.cookies.metricool.text" 
                defaultMessage="Metricool es una empresa española de analítica, nos permite analizar la cantidad de usuarios que accede a la página, el país y provincia de origen, y el sitio web de origen. No usamos esta información con motivos publicitarios ni relacionamos esta información con usuarios registrados en nuestra plataforma."
              />
            </p>
          </div>
          <div className={styles.actions}>
            <Button  onClick={() => dispatch(giveConsent())}>
              <FormattedMessage 
                id="components.cookies.button.consent" 
                defaultMessage="Dar consentimiento"
              /></Button>
            <Button dark  onClick={() => dispatch(denyConsent())}>
              <FormattedMessage 
                id="components.cookies.button.noconsent" 
                defaultMessage="No dar consentimiento"
              /></Button>
          </div>
        </div>
      </div>
    </div>
  )
  
  return (
    moreInfo === false ? firstLayer() : secondLayer()
  )

}


export default CookieConsent
