import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {IntlContext} from '../../helpers/IntlContext'
import {userSignin, resetUser} from '../../actions/auth_actions'
import {rememberPassword} from '../../actions/public_actions'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import {Input} from '../../components/FormElements'

import styles from './Login.module.scss'

const Login = props => {
  const [isLoginPage, setLoginPage] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [uerror, setError] = useState(null)

  const dispatch = useDispatch()
  const intl = useIntl()
  const {locale} = React.useContext(IntlContext)

  //Redux Store
  const {error, passwordRememberSuccess, isLoading} = useSelector(state => ({
    error: state.userLogin.error,
    passwordRememberSuccess: state.userLogin.passwordRememberSuccess,
    isLoading: state.userLogin.loading    
  }))

  useEffect(()=>{
    dispatch(resetUser())
  },[dispatch])


  const resetState = () => {
    setError(null)
    dispatch(resetUser())
  }

  const onUserSignin = () => {
    //Resetting any error message
    resetState()
    
    if (username === '' || password === '') {
      setError(intl.formatMessage({id: 'pages.login.missingfields', defaultMessage: 'Por favor rellena los campos'}))
    } else {
      let userDets = {
        username,
        password
      }
      dispatch(userSignin(userDets))
    }
  }

  const onPasswordReset = () => {
    //Resetting any error message
    resetState()

    if (username === '') {
      setError(intl.formatMessage({id: 'pages.login.missingfields', defaultMessage: 'Por favor rellena los campos'}))
    } else {
      dispatch(rememberPassword({username, language: locale}))
    }
  }

  const errorMessage = () => {
    if (uerror) {
      return (
        <Alert key="state" >{uerror}</Alert>
      )
    }

    if (error) {
      return (
        <Alert key="props" >{error}</Alert>
      )    
    }
  }

  const loginContent = () => {
    return (
      <motion.div layout className={styles.loginCard}>

        {errorMessage()}

        <p className={styles.titleText}><FormattedMessage id="pages.login.welcome" defaultMessage="Bienvenido de nuevo" /></p>
        <form className={styles.signForm} onSubmit={e => {
          onUserSignin(); e.preventDefault() 
        }}
        >
          <Input className="mb-5" type="text" placeholder={intl.formatMessage({id: 'pages.login.username', defaultMessage: 'Nombre de usuario'})}  onChange={(e) => setUsername(e.target.value)} />
          <Input className="mb-5" type="password" placeholder="●●●●●●●●"  onChange={(e) => setPassword(e.target.value)}  />
          <Button className="mb-5 text-base" isLoading={isLoading}>{isLoading ? intl.formatMessage({id: 'pages.login.connecting', defaultMessage: 'Conectando...'}):intl.formatMessage({id: 'pages.login.connect', defaultMessage: 'Conéctate'})}</Button>
        </form>

        <Button transparent className={styles.step40}>
          <span onClick={() => {
            resetState()
            setLoginPage(false)
          }} className={styles.noPassText}
          ><FormattedMessage id="pages.login.lostpass" defaultMessage="Olvidé mi contraseña" /></span>
        </Button>

        <p className={styles.titleText}><FormattedMessage id="pages.login.notregistered" defaultMessage="¿No estás registrado?" /></p>
        <Link to="/sign-up"><Button dark className={`${styles.signup} text-base`}><FormattedMessage id="pages.login.createbutton" defaultMessage="Créate una cuenta" /></Button></Link>
      </motion.div>
    )
  }

  const rememberPassContent = () => {
    return (
      <div className={styles.loginCard}>

        <AnimatePresence>
          {errorMessage()}
        </AnimatePresence>

        <AnimatePresence>
          {passwordRememberSuccess && <Alert key="passwordReset" success><FormattedMessage id="pages.login.reset.alert" defaultMessage="Si el nombre existe, has recibido un correo electrónico con instrucciones para recuperar la contraseña." /></Alert>}
        </AnimatePresence>


        <p className={styles.titleText}><FormattedMessage id="pages.login.reset.p1" defaultMessage="Restablece tu contraseña" /></p>
        <form className={styles.signForm} onSubmit={e => {
          onPasswordReset(); e.preventDefault() 
        }}
        >
          <Input type="text" placeholder={intl.formatMessage({id: 'pages.login.useropass', defaultMessage: 'Nombre de usuario o correo electrónico'})} onChange={(e) => setUsername(e.target.value)} />
          <Button className={styles.step20} isLoading={isLoading}>{isLoading ? intl.formatMessage({id: 'pages.login.resetting', defaultMessage: 'Restableciendo...'}):intl.formatMessage({id: 'pages.login.reset', defaultMessage: 'Restablecer'})}</Button>
        </form>

        <Button transparent className={styles.step40}>
          <span onClick={() => {
            resetState()
            setLoginPage(true)
          }} className={styles.noPassText}
          ><FormattedMessage id="pages.login.reset.button.back" defaultMessage="Volver al login" /></span>
        </Button>

      </div>
    )
  }

  return (
    <div className={styles.card}>
      {isLoginPage ? loginContent() : rememberPassContent()}
      <div className={styles.adCard}>
        <h1>
          <FormattedMessage 
            id="pages.login.adcard.title" 
            defaultMessage="¡Bienvenido a Beats4seven!{br} encuentra el beat perfecto para tu proyecto" 
            values ={{br: <br />}}
          />
        </h1>
        <p><FormattedMessage id="pages.login.adcard.p1" defaultMessage="Estás a punto de navegar por nuestro catálogo enorme de instrumentales, que crece a diario, para que encuentres lo que necesitas, desde lo más clásico hasta lo más actual, dando alas a tu inspiración." /></p>
        <br />
        <p>
          <FormattedMessage 
            id="pages.login.adcard.p2" 
            defaultMessage="Si tienes cualquier duda puedes visitar nuestra página de preguntas frecuentes haciendo clic <l>aquí</l>." 
            values={{
              l: chunks => <Link className={styles.link} to="/faq">{chunks}</Link>
            }}
          /> 
        </p>
      </div>
    </div>
  )
}

export default Login
