import React from 'react'
import {connect} from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import {sendErrorReport} from '../../actions/app_actions'
import Footer from '../../components/Footer'
import styles from './ErrorBoundary.module.scss'
import Button from '../../components/Button'
import Header from '../../components/Header'
import errorImg from '../../assets/img/error-resource.svg'



class ErrorBoundary extends React.Component {
  state = {hasError: false}

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }
  
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.props.sendErrorReport(error.toString(), btoa(JSON.stringify(errorInfo.componentStack)))
    console.debug(error.toString())
    console.debug(errorInfo.componentStack)
  }

  handleError() {
    //this.props.navigate('/')
    this.setState({hasError: false})
  }

  
  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className={styles.background}>
            <div className={styles.container}>
              <Header />
              <div className={styles.content}>
                <div className={styles.left}>
                  <h2 className={styles.h2}>Ha ocurrido un error</h2>
                  <p className={styles.p}>¡Lo sentimos!, Nuestros ingenieros han recibido un informe y ya se han puesto en marcha para resolver el problema.</p>
                  <Button onClick={() => this.handleError()} className={styles.button} dark>Vuelve a la portada</Button>
                </div>
                <div className={styles.right}>
                  <img className={styles.img} src={errorImg} alt="An Error has happened" />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )
    }
  
    return this.props.children 
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return (
      <Component
        {...props}
        router={{location, navigate, params}}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter(connect(null, {sendErrorReport})(ErrorBoundary))
