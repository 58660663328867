import React, {useEffect, useState} from 'react'
import {IntlProvider} from 'react-intl'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ja'
import text_en from '../locale/en.json'
import text_es from '../locale/es.json'
import text_de from '../locale/de.json'
import text_fr from '../locale/fr.json'
import text_ja from '../locale/ja.json'

const Context = React.createContext()

const langs = {
  es: text_es,
  en: text_en,
  de: text_de,
  fr: text_fr,
  ja: text_ja
}

const langDefaults = () => {
  const usersLocale = navigator.language.split(/[-_]/)[0]
  let defaultMessage
  
  switch (usersLocale) {
  case 'es':
    defaultMessage = langs.es
    break
  case 'de':
    defaultMessage = langs.de
    break
  case 'fr':
    defaultMessage = langs.fr
    break
  case 'ja':
    defaultMessage = langs.ja
    break
  case 'en':
  default:
    defaultMessage = langs.en
    break
  }
  return [usersLocale, defaultMessage]
}

const IntlProviderWrapper = (props) => {

  const [usersLocale, defaultMessage] = langDefaults()
  const [locale, setLocale] = useState(usersLocale)
  const [messages, setMessages] = useState(defaultMessage)

  //Aplicar castellano a la localización de Moment
  useEffect(()=>{
    moment.locale(locale)
  },[locale])

  const switchLanguage = (lang) => {
    setMessages(langs[lang])
    setLocale(lang)
    moment.locale(lang)
  }

  return (
    <Context.Provider value={{locale, switchLanguage}}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale={locale}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

export {IntlProviderWrapper, Context as IntlContext, langDefaults}
