import {motion} from 'framer-motion'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import Card from '../../../../components/Card'

import styles from './DisconnectMenu.module.scss'

const DisconnectMenu = (props) => (
  <motion.div 
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{opacity: 0}}
  >
    <Card className={`${styles.userMenu} z-50`}>
      <ul>
        <li onClick={() => props.userSignout()}>
          <FormattedMessage 
            id="components.header.disconnect" 
            defaultMessage="Desconectar"
          />
        </li>
      </ul>
    </Card>

  </motion.div>
)

export default DisconnectMenu
