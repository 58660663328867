import React, {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, Link} from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

import {setSignupSub} from '../../actions/signup_actions'
import Accordion from '../../components/Accordion'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import Pricing from '../../components/Pricing'
import CookieConsent from '../../components/CookieConsent'
import Feature from './Feature'
import styles from './Home.module.scss'

import b4sTypo from '../../assets/img/logo-typo.svg'
import b4sLogo from '../../assets/img/logo-b4s.svg'
import headerImg from '../../assets/img/home-vector.svg'
import redbullImg from '../../assets/img/logos/redbull.svg'
import cnnImg from '../../assets/img/logos/cnn.svg'
import universalImg from '../../assets/img/logos/universal.svg'
import chocolatexImg from '../../assets/img/logos/chocolatex.svg'
import hhgroupsImg from '../../assets/img/logos/hhgroups.svg'
import warnerImg from '../../assets/img/logos/warner.svg'
import sonyImg from '../../assets/img/logos/sony.svg'
import defjamImg from '../../assets/img/logos/defjam.svg'

const Home = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()
  const [showTestimonials, setShowTestimonials] = useState(false)


  const {isEuropean} = useSelector(state => ({
    isEuropean: state.visitor.isEuropean,
  }))

  useEffect(()=>{
    ReactPixel.track('ViewContent', {content_name: 'Home'}) 
  },[])

  const setSubscription = (sub) => {
    dispatch(setSignupSub(sub))
    navigate('/sign-up/registration')
  }

  return (
    <React.Fragment>
      {isEuropean ? <CookieConsent /> : ''}
      <div className={styles.Home}>
        <div className={styles.head}>
          <div className="container">
            <div className={styles.headLine}>
              <div className={styles.logo}>
                <img className="hidden sm:block" alt="logo" src={b4sTypo} />
                <img className="sm:hidden" alt="logo" src={b4sLogo} />
              </div>
              <div className="flex space-x-4">
                <Link to="/login"><Button small light className="uppercase font-semibold" ><FormattedMessage id="pages.home.header.login" defaultMessage="Login" /></Button></Link>
                <Link to="/sign-up"><Button small light className="uppercase font-semibold" ><FormattedMessage id="pages.home.header.register" defaultMessage="Registrarme" /></Button></Link>
              </div>
            </div>
            <div className={`${styles.headContent}`}>
              <div className="w-full md:w-2/3">
                <div className="text-white mb-8 mt-16">
                  <h1 className="text-3xl mb-6 font-bold uppercase"><FormattedMessage id="pages.home.header.title" defaultMessage="Encuentra el beat perfecto para tu canción" /></h1>
                  <p className="text-base"><FormattedMessage id="pages.home.header.p1" defaultMessage="Beats4seven es la plataforma que estabas esperando. El sueño de cantantes y raperos/as." /></p>
                  <p className="text-base"><FormattedMessage id="pages.home.header.p2" defaultMessage="Descarga beats profesionales para usarlos en tus canciones y monetiza tu música en todas partes." /></p>
                </div>
                <div className="flex mb-40 sm:mb-0">
                  <Link className="w-full sm:w-auto" to="/beats"><Button light className="px-20 py-3"><FormattedMessage id="pages.home.header.cta1" defaultMessage="DESCARGAR BEATS" /></Button></Link>
                </div>
              </div>
              <div className="hidden md:block md:w-1/3 lg:mb-16">
                <img src={headerImg} alt="Ilustración cabecera Beats4seven" />
              </div>
            </div>
          </div>
        </div>
        <div id="features" className={`${styles.features} ${styles.story} ${styles.sectionBlue1}`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl mb-20 font-bold tracking-tight text-left md:text-center uppercase"><FormattedMessage id="pages.home.how.title" defaultMessage="Tu música{br}merece lo mejor" values={{br: <br />}} /></h2>
            </div>

            <div className="flex flex-col md:flex-row mb-16">
              <div className="w-full md:w-1/3 mb-4 sm:mb-0 p-8 sm:mr-4 flex-col gap-x-4 rounded">
                <i className="fal fa-fist-raised text-4xl mb-4" />
                <h4 className="text-2xl font-medium mb-1"><FormattedMessage id="pages.home.how.card1.title" defaultMessage="Únete" /></h4>
                <p><FormattedMessage id="pages.home.how.card1.p" defaultMessage="Es simple, te unes a nuestra familia y empiezas a descargar beats con la mejor licencia del mercado. Sin límites en streams, sin límites en nada." /></p>
              </div>

              <div className="w-full md:w-1/3 mb-4 sm:mb-0 p-8 sm:mx-4 flex-col gap-x-4 rounded">
                <i className="fal fa-speaker text-4xl mb-4" />
                <h4 className="text-2xl font-medium mb-1"><FormattedMessage id="pages.home.how.card2.title" defaultMessage="Navega" /></h4>
                <p><FormattedMessage id="pages.home.how.card2.p" defaultMessage="Un catálogo enorme de beats que crece cada mes, seleccionados uno a uno. En serio, no subimos beats por subir, nuestros productores han trabajado con los mejores artistas." /></p>
              </div>

              <div className="w-full md:w-1/3 mb-4 sm:mb-0 p-8 sm:ml-4 flex-col gap-x-4 rounded">
                <i className="fal fa-joint text-4xl mb-4" />
                <h4 className="text-2xl font-medium mb-1"><FormattedMessage id="pages.home.how.card3.title" defaultMessage="Crea" /></h4>
                <p><FormattedMessage id="pages.home.how.card3.p" defaultMessage="Cuando tienes buena música la inspiración viene sola. Crea y publica tus canciones en todas partes, Spotify, Apple Music, Tidal, Tik Tok…etc. Monetiza tu música y empieza a crecer como artista." /></p>
              </div>
            </div>

            <div className="mb-10"></div>

            <div className="grid grid-cols-3 gap-10 sm:grid-cols-4">
              <div className="flex align-middle justify-center"><img className="w-28" alt="red bulllogo" src={redbullImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="sony logo" src={sonyImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="cnn logo" src={cnnImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="universal logo" src={universalImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="defjam logo" src={defjamImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="chocolatex logo" src={chocolatexImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="hhgroups logo" src={hhgroupsImg} /></div>
              <div className="flex align-middle justify-center"><img className="w-28" alt="warner logo" src={warnerImg} /></div>
            </div>

          </div>
        </div>
    
        {/*}
        <div id="logos" className={`${styles.logos} ${styles.sectionBlueSpecial}`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold tracking-tight"><FormattedMessage id="pages.home.logos.title" defaultMessage="CONFÍAN EN NUESTRO SONIDO" /></h2>
              <p><FormattedMessage id="pages.home.logos.subtitle" defaultMessage="Nuestros productores trabajan con las mejores empresas musicales" /></p>
            </div>
            <div className={styles.logoRow1}>
              <img alt="logo" src={redbullImg} />
              <img alt="logo" src={cnnImg} />
              <img alt="logo" src={universalImg} />
              <img alt="logo" src={rtveImg} />
              <img alt="logo" src={chocolatexImg} />
            </div>
            <div className={styles.logoRow2}>
              <img alt="logo" src={boaImg} />
              <img alt="logo" src={hhgroupsImg} />
              <img alt="logo" src={warnerImg} />
              <img alt="logo" src={sonyImg} />
            </div>
          </div>
        </div>
        */}
        
        <div id="planes" className={`${styles.subscribe} py-20 bg-gradient-to-r from-b4s-blue-5 to-b4s-purple-1  text-white`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold mb-20 uppercase text-left md:text-center m-auto"><FormattedMessage id="pages.planes.title" defaultMessage="Más música.{br} más creatividad.{br} sin límites con nuestra licencia." values={{br: <br />}} /></h2>
            </div>
            <div className="w-full flex justify-center">
              <Pricing buttonAction={setSubscription} />
            </div>
          </div>
        </div>
        
        <div className={`${styles.sectionBlue1} ${styles.nuestrosBeats}`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold mb-20 uppercase text-left md:text-center tracking-tight"><FormattedMessage id="pages.home.ourbeats.title" defaultMessage="¿Te reconoces en alguna de estas situaciones?" /></h2>
            </div>
            <ul className={styles.featurelist}>                
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li1', defaultMessage: 'Acumulas letras de canciones que nunca llegan a publicarse'})} />
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li2', defaultMessage: 'Sientes que no progresas como artista'})} />
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li3', defaultMessage: 'Publicas canciones que luego no tienen repercusión'})} />
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li4', defaultMessage: 'Navegas buscando beats pero no puedes permitirte el precio de las licencias ilimitadas'})} />
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li5', defaultMessage: 'No consigues sonar como suenan los artistas que escuchas en la radio'})} />
              <Feature light text={intl.formatMessage({id: 'pages.home.ourbeats.li6', defaultMessage: 'Necesitas beats que te inspiren porque te sientes bloqueado creativamente'})} />
            </ul>
          </div>
        </div>


        <div className={`${styles.sectionBlueSpecial} testimonios`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold mb-20 uppercase text-left md:text-center m-auto"><FormattedMessage id="pages.home.testimonios.title" defaultMessage="Que dice la gente" /></h2>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-center">
              <div className="px-3 md:w-1/3">
                <Testimonial
                  name={intl.formatMessage({id:'components.testimonial.name1', defaultMessage:'HHGroups'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote1', defaultMessage:'Un concepto mejor, más simple. Una plataforma que te ofrece instrumentales a través de suscripción, un sistema avanzado para mejorar el acceso a beats de calidad'})}
                  role={intl.formatMessage({id:'components.testimonial.role1', defaultMessage:'Medio de comunicación referente de la cultura Hip Hop'})}
                />

                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name3', defaultMessage:'Mr Brey'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote3', defaultMessage:'Me han ayudado mucho porque la calidad de sus beats es muy buena y he podido grabar 2 álbumes gracias a ellos'})}
                  role={intl.formatMessage({id:'components.testimonial.role3', defaultMessage:'Usuario de Beats4seven'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name4', defaultMessage:'Javo Poisonfree'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote4', defaultMessage:'Hace 2 años no podía invertir dinero en licencias de beats. Gracias a Beats4seven ya tengo mi perfil de artista en Spotify y disfruto de beats increíbles por menos de lo que te cuesta un menú en McDonald´s'})}
                  role={intl.formatMessage({id:'components.testimonial.role4', defaultMessage:'Usuario de Beats4seven'})}
                />
              </div>
              <div className="px-3 md:w-1/3">
                <Testimonial
                  name={intl.formatMessage({id:'components.testimonial.name2', defaultMessage:'Iamrap'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote2', defaultMessage:'De manera sincera, si queréis empezar a trabajar de manera profesional o simplemente estáis buscando el mejor sonido para brillar como artistas, esta es la plataforma'})}
                  role={intl.formatMessage({id:'components.testimonial.role2', defaultMessage:'Medio de comunicación referente de la cultura Hip Hop'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}

                  name={intl.formatMessage({id:'components.testimonial.name5', defaultMessage:'Santiuve'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote5', defaultMessage:'Ojalá hubiera existido Beats4seven mucho antes, no he podido publicar trabajos antiguos en plataformas por problemas con instrumentales y eso con Beats4seven no habría pasado. Son gente muy seria y la interfaz es muy chula'})}
                  role={intl.formatMessage({id:'components.testimonial.role5', defaultMessage:'Uno de los artistas más respetados en España, usuario de Beats4seven'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name7', defaultMessage:'Sitofonk'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote7', defaultMessage:'Estamos hablando de beats de calidad creados por productores profesionales. Mucha variedad en estilos y un precio totalmente asumible'})}
                  role={intl.formatMessage({id:'components.testimonial.role7', defaultMessage:'Humorista, Showman, cantante'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name10', defaultMessage:'Woyza'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote10', defaultMessage:'Muchos beats de música urbana creado por productores muy buenos. Te va a ayudar sin duda a encontrar la inspiración que necesitas'})}
                  role={intl.formatMessage({id:'components.testimonial.role10', defaultMessage:'Una de las mejores voces femeninas del rap hispano'})}
                />
              </div>
              <div className="px-3 md:w-1/3">
                <Testimonial
                  name={intl.formatMessage({id:'components.testimonial.name6', defaultMessage:'Capaz, referente del rap español'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote6', defaultMessage:'Os recomiendo la plataforma Beats4seven, vais a encontrar instrumentales muy variadas y un servicio de mezcla y mastering perfecto. Para que vuestra música suene como tiene que sonar'})}
                  role={intl.formatMessage({id:'components.testimonial.role6', defaultMessage:'Artista destacado de habla hispana, miembro de Hp Squad'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name8', defaultMessage:'Kit Z'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote8', defaultMessage:'Desde que Beats4seven nació, nos han dado a los artistas muchas posibilidades. Ahora tenemos las herramientas para poder seguir avanzando en nuestro camino musical'})}
                  role={intl.formatMessage({id:'components.testimonial.role8', defaultMessage:'Miembro de Beats4seven'})}
                />
                <Testimonial
                  className={!showTestimonials ? 'hidden' : ''}
                  name={intl.formatMessage({id:'components.testimonial.name9', defaultMessage:'Lopes'})}
                  quote={intl.formatMessage({id:'components.testimonial.quote9', defaultMessage:'Gente seria, instrumentales de calidad, a un precio increíble'})}
                  role={intl.formatMessage({id:'components.testimonial.role9', defaultMessage:'Artista destacado en el panorama de habla hispana'})}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <Button className={showTestimonials ? 'hidden' : 'w-1/3'} light onClick={() => setShowTestimonials(true)}><FormattedMessage id="pages.home.testimonials.seeall" defaultMessage="Ver todo" /></Button> 
            </div>
          </div>
        </div>

        <div className={`${styles.sectionBlue2} faq`}>
          <div className="container">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold mb-20 uppercase text-left md:text-center m-auto"><FormattedMessage id="pages.home.faq.title" defaultMessage="¿Tienes preguntas?" /></h2>
            </div>
            <div className="w-full flex flex-col justify-center">

              <Accordion light className="w-full" title={intl.formatMessage({id:'components.faq.whyb4s.title', defaultMessage:'¿Por qué elegir Beats4seven?'})}>
                <FormattedMessage id="components.faq.whyb4s.desc"  defaultMessage="En Beats4Seven hemos eliminado el pagar por cada beat y te ofrecemos la posibilidad de descargar 5 beats al mes por una inversión mínima. Además, cada beat viene con una licencia fácil y sin límites, lo que te permite subir tu canción a todas las plataformas digitales, como Spotifty, Apple Music, Tidal, etc. De manera completamente legal y ganar dinero con tu música ¡Únete a nuestra familia y obtén acceso ilimitado a beats profesionales!" />
              </Accordion>

              <Accordion light className="w-full" title={intl.formatMessage({id:'components.faq.licence.title', defaultMessage:'¿Que incluye nuestra licencia?'})}>
                <FormattedMessage id="components.faq.licence.desc"  defaultMessage="Descubre la mejor licencia que existe en el mercado porque puedes subir tus canciones a todas las plataformas digitales sin limites en streams, sin limites en ventas, y sin límites de uso. Además, monetiza tu música en todo el universo. Además, si algún día quieres cancelar tu suscripción, podrás seguir utilizando los beats que ya tenías descargados, para siempre. Punto." />
              </Accordion>

              <Accordion light className="w-full" title={intl.formatMessage({id:'components.faq.cancel.title', defaultMessage:'¿Que ocurre cuando cancelas tu suscripción?'})}>
                <FormattedMessage id="components.faq.cancel.desc"  defaultMessage="En Beats4Seven llevamos más de 5 años ofreciendo nuestro servicio de suscripción de beats a miles de usuarios satisfechos. Si deseas cancelar tu suscripción, puedes hacerlo en cualquier momento desde tu área de usuario o poniéndote en contacto con nuestro equipo de soporte técnico. Una vez cancelada la suscripción, podrás seguir haciendo uso de los beats que hayas descargado previamente sin ningún tipo de limitación." />
              </Accordion>

            </div>
          </div>
        </div>

        <div className={`${styles.sectionBlue1} final`}>
          <div className="container">
            <div className="text-center">
              <h2 className="text-3xl font-bold mb-10 uppercase text-left md:text-center m-auto"><FormattedMessage id="pages.home.final.title" defaultMessage="Beats de calidad. Licencias sin límites.{br} Beats4Seven" values={{br: <br />}} /></h2>
            </div>
            <div className="flex justify-center">
              <Link className="w-full sm:w-auto uppercase" to="/beats"><Button light className="p-20"><FormattedMessage id="pages.home.final.cta" defaultMessage="Escuchar Beats" /></Button></Link>
            </div>
          </div>
        </div>

      </div>

      {/* Boton flotante con icono fontawesome de auriculares */}
      <div className="fixed bottom-0 right-0 mb-10 mr-10">
        <Link to="/beats">
          <div className="flex items-center justify-center w-16 h-16 bg-b4s-pink-7 rounded-full shadow-lg cursor-pointer animate-bounce">
            <i className="fas fa-headphones text-2xl text-white" />
          </div>
        </Link>
      </div>

      <Footer />
    </React.Fragment>
  )
}

const Testimonial = ({quote, role, name, className}) => {
  return (
    <div className={` ${className} w-full mx-auto rounded border border-white/50 p-5 text-white font-light mb-6`}>
      <div className="w-full">
        <p className="text-center font-light text-sm"><span className="text-lg leading-none italic font-bold mr-2">"</span>{quote}<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
        <p className="mt-10 font-bold text-base uppercase">{name}</p>
        <p className="text-xs">{role}</p>
      </div>
    </div>
  )
}

export default Home
