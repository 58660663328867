import * as types from '../actions/types'

const initialState = {
  membership: null,
  paymentMethod: null,
  paymentAddress: null,
  renewalDate: null,
  transactionId: null,
  error: null,
  cancelError: null,
  isLoading: null,
  isLoadingCancel: null,
  isCancelled: false,
  isPaypalLoading: false,
  isStripeLoading: false,
  isStripePaying: false,
  isStripeProcessed: false,
  stripeClientSecret: null,
  subChange: false
}

const userSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_USER_SUBSCRIPTION:
    return  {...initialState, isLoading: true}
  case types.GET_USER_SUBSCRIPTION_SUCCESS:
    return  {
      ...initialState, 
      paymentMethod: action.payload.paymentMethod,
      paymentAddress: action.payload.paymentAddress,
      renewalDate: action.payload.renewalDate,
      isCancelled: action.payload.cancelled
    }
  case types.CHOOSE_USER_SUBSCRIPTION:
    return {...state, membership: action.payload}
  case types.CANCEL_USER_SUBSCRIPTION:
    return  {...state, isLoadingCancel: true}
  case types.CANCEL_USER_SUBSCRIPTION_FAIL:
    return  {...state, cancelError: true, isLoadingCancel: false}
  case types.CANCEL_USER_SUBSCRIPTION_SUCCESS:
    return  {...state, isCancelled: true, isLoadingCancel: false}
  case types.SET_USER_SUBSCRIPTION_PAYPAL:
    return  {...state, isPaypalLoading: true, error: false}
  case types.SET_USER_SUBSCRIPTION_PAYPAL_SUCCESS:
    return  {...state, isPaypalLoading: false, error: false, formData: action.payload}
  case types.SET_USER_SUBSCRIPTION_PAYPAL_FAIL:
    return  {...state, isPaypalLoading: false, error: action.payload, formData: []}
  case types.GET_USER_SUBSCRIPTION_STRIPE:
    return  {...state, isStripeLoading: true, error: false}
  case types.GET_USER_SUBSCRIPTION_STRIPE_SUCCESS:
    return  {
      ...state, 
      isStripeLoading: false, 
      error: false, 
      isStripeProcessed: false, 
      stripeClientSecret: action.payload.clientSecret, 
      subscriptionId: action.payload.subscriptionId
    }
  case types.SET_USER_SUBSCRIPTION_STRIPE:
    return  {...state, isStripePaying: true, error: false}
  case types.SET_USER_SUBSCRIPTION_STRIPE_SUCCESS:
    return  {...state, isStripePaying: false, error: false, isStripeProcessed: true, stripeClientSecret: null}
  case types.GET_USER_SUBSCRIPTION_STRIPE_FAIL:
  case types.SET_USER_SUBSCRIPTION_STRIPE_FAIL:
    return  {...state, isStripePaying: false, error: true, stripeClientSecret: null}    
  case types.RESET_USER_SUBSCRIPTION_ERRORS:
    return  {...state, error: false}
  case types.RESET_USER_SUBSCRIPTION:
    return  {...initialState}
  default: 
    return state
  }
}

export default userSubscriptionReducer
