import * as types from '../actions/types'

const initialState = {
  beats:[],
  bpm: {min: null, max: null},
  keys: [],  
  isLoading: false,
  error: false
}

const userBeatsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_USER_BEATS:
    return  {...initialState, isLoading: true}
  case types.GET_USER_BEATS_SUCCESS:
    return  {
      ...initialState,
      beats: action.payload.beats, 
      bpm: action.payload.filters.bpm, 
      keys: action.payload.filters.keys
    }
  case types.GET_USER_BEATS_FAIL:
    return  {...initialState, error: true}
  case types.LOAD_USER_BEAT:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: true} : b)}
  case types.LOAD_USER_BEAT_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: false} : b)}
  case types.LOAD_USER_BEAT_UPGRADE:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isUpLoading: true} : b)}
  case types.LOAD_USER_BEAT_UPGRADE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isUpLoading: false, subscription: 'vip'} : b)}
  case types.LOAD_USER_BEAT_UPGRADE_FAIL:
    return  {...state, error: true, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isUpLoading: false} : b)}
  case types.LOAD_USER_BEAT_LICENSE:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: true} : b)}  
  case types.LOAD_USER_BEAT_LICENSE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading: false} : b)}
  case types.SET_USER_BEAT_FAVOURITE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, favourite: !b.favourite} : b)}
  case types.SET_USER_BEAT_PURCHASE_SUCCESS:
    return  {...state, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, purchased: !b.purchased, isLoading:false} : b)}
  case types.SET_USER_BEAT_PURCHASE_FAIL:
    return  {...state, error: true, beats:state.beats.map(b => b.id === action.payload.beatid ? {...b, isLoading:false} : b)}
  case types.RESET_USER_BEATS:
    return {...initialState}
  default: 
    return state
  }
}

export default userBeatsReducer
