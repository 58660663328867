import 'core-js'
import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/es/integration/react'

import App from './pages/App'
import ErrorBoundary from './helpers/ErrorBoundary'
import {IntlProviderWrapper} from './helpers/IntlContext'
import ScrollToTop from './helpers/ScrollToTop'
import configureStore from './reducers'

//Application css import
import './assets/styles/app.scss'
import './assets/styles/fa5.min.css'

const container = document.getElementById('root')
const root = createRoot(container)
const {persistor, store} = configureStore()

root.render(
  <Provider store={store}>
    <IntlProviderWrapper>
      <PersistGate  persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </PersistGate>
    </IntlProviderWrapper>
  </Provider>
)
