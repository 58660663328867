import * as types from '../actions/types'

const initialState = {
  statsLoading: false,
  statsEerror: false,
  monthList: [{month: new Date().getMonth()+1, year: new Date().getFullYear()}],
  stats: {downloads: 0, revenue: '0€', plays: 0, favourites: 0},
  rankingLoading: false,
  rankingEerror: false,
  rankingMonth: [],
  rankingYear: [],
  beatLoading: false,
  beatEerror: false,
  activeBeats: [],
  inactiveBeats: [],
}

const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PRODUCER_STATS:
    return  {...state, statsError: false, statsLoading: true}
  case types.GET_PRODUCER_STATS_SUCCESS:
    return  {
      ...state,
      statsLoading: false, 
      statsError: false,
      monthList: [...action.payload.monthList],
      stats: action.payload.stats,
    }
  case types.GET_PRODUCER_STATS_FAIL:
    return  {...state, statsLoading: false, statsError: true}
  case types.GET_PRODUCER_RANKING:
    return  {...state, rankingError: false, rankingLoading: true}
  case types.GET_PRODUCER_RANKING_SUCCESS:
    return  {
      ...state,
      rankingLoading: false, 
      rankingError: false,
      rankingMonth: [...action.payload.month],
      rankingYear: [...action.payload.year],
    }
  case types.GET_PRODUCER_RANKING_FAIL:
    return  {...state, rankingLoading: false, rankingError: true}
  case types.GET_PRODUCER_BEATS:
    return  {...state, beatsError: false, beatsLoading: true}
  case types.GET_PRODUCER_BEATS_SUCCESS:
    return  {
      ...state,
      beatsLoading: false, 
      beatsError: false,
      activeBeats: [...action.payload.active],
      inactiveBeats: [...action.payload.inactive],
    }
  case types.GET_PRODUCER_BEATS_FAIL:
    return  {...state, beatsLoading: false, beatsError: true}
  case types.RESET_PRODUCER:
    return {...initialState}
  default: 
    return state
  }
}

export default affiliateReducer
