import * as types from '../actions/types'

const initialState = {
  passwordRememberSuccess: false,
  passwordResetSuccess: false,
  resetError: false,
  resetLoading: false,
  error: null,
  loading: false,
}

const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.REMEMBER_PASSWORD:
  case types.USER_SIGNIN:
    return  {...initialState, loading: true}
  case types.REMEMBER_PASSWORD_FAIL:
  case types.USER_SIGNIN_FAIL:
    return  {...initialState, error: action.error}
  case types.REMEMBER_PASSWORD_SUCCESS:
    return  {...initialState, passwordRememberSuccess: true}
  case types.RESET_PASSWORD:
    return  {...initialState, resetLoading: true}
  case types.RESET_PASSWORD_SUCCESS:
    return  {...initialState, passwordResetSuccess: true}
  case types.RESET_PASSWORD_FAIL:
    return  {...initialState, resetError: true}
  case types.RESET_USER_LOGIN:
    return  {...initialState}
  default: 
    return state
  }
}

export default userLoginReducer
