import * as types from '../actions/types'

const initialState = {
  beats:[],
  bpm: {min: null, max: null},
  keys: [],
  isLoading: false,
  error: false
}

const beatsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_BEATS:
    return  {...initialState, isLoading: true}
  case types.GET_BEATS_SUCCESS:
    return  {
      ...initialState, 
      beats: action.payload.beats, 
      bpm: action.payload.filters.bpm, 
      keys: action.payload.filters.keys
    }
  case types.GET_BEATS_FAIL:
    return  {...initialState, error: true}
  case types.RESET_BEATS:
    return {...initialState}
  default: 
    return state
  }
}

export default beatsReducer
