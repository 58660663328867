import axios from 'axios'
import * as types from '../actions/types'

export const apicaller = async ({url, headers, method = 'get', data}, dispatch) => {
  try {

    const response = await axios({method, url, headers, data, withCredentials: true})
    return response.data
  
  } catch (err) {
    
    if (err.message === 'Token expired') {
      dispatch({type: types.EXPIRED_TOKEN})
    }
    
    if (err.response) {
      if (err.response.status === 403) {
        dispatch({type: types.EXPIRED_TOKEN})
      } else {
        throw (err.response)
      }
    }
  }
}
  