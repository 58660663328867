import * as types from '../actions/types'

const initialState = {
  username: '',
  firstname: '',
  lastname: '',
  displayname: '',
  language: null,
  credits: 0,
  role: 'guest',
  subscription: null,
  awaitingPayment: false, // User just paid and we're waiting for webhook
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_USER_SUCCESS:
    
    return {
      ...state,
      isLoading: false,
      username: action.payload.username,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      displayname: action.payload.firstname ? `${action.payload.firstname} ${action.payload.lastname}` : action.payload.username,
      language: action.payload.language,
      credits: action.payload.credits,
      role: action.payload.role,
      subscription: action.payload.subscription,
    }
  case types.USER_UPDATE_SUCCESS: 
    return {
      ...state,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      email: action.payload.email,
      displayname: action.payload.firstname ? `${action.payload.firstname} ${action.payload.lastname}` : action.payload.username,
    }
  case types.SET_USER_BEAT_PURCHASE_SUCCESS:
    return {
      ...state,
      credits: state.credits-1
    }
  case types.GET_USER_CREDITS_SUCCESS:
    return {
      ...state,
      credits: action.payload,
    }
  case types.SET_USER_AWAITING_PAYMENT:
    return {
      ...state,
      awaitingPayment: true
    }
  case types.SET_USER_DISABLE_AWAITING_PAYMENT:
    return {
      ...state,
      awaitingPayment: false
    }
  case types.RESET_USER:
    return  {...initialState}
  default: 
    return state
  }
}

export default userReducer
