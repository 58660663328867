import React from 'react'
import styles from './Home.module.scss'
import check from '../../assets/img/check.svg'

const sadFace = <i className={`fas fa-sad-tear ${styles.sadIcon}`}></i>
const checkImg = <img className={styles.checkImg} src={check} alt="yes" />

const Feature = ({text, sad, light}) => (
  <li className={`${styles.featureLi} ${light ? styles.light : ''}`} >
    {sad ? sadFace : checkImg}
    <span>{text}</span>
  </li>
)
  
export default Feature
  