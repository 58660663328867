import React from 'react'
import {motion} from 'framer-motion'
import styles from './Alert.module.scss'

const Alert = (props) => {

  let className = `${styles.alert} ${props.className}`
  if (props.success) className = `${className} ${styles.success}`
  
  return (
    <motion.div
      initial={{y:-50, opacity: 0}}
      animate={{y:0, opacity: 1}}
      transition={{type: 'spring', stiffness: 100}}
      className={className}
    >
      {props.children}
    </motion.div>
  )
}

export default Alert
