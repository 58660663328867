import * as types from '../actions/types'

const initialState = {
  setInfoLoading: false,
  setInfoError: false,
  setInfoErrorMessage: '',
  setInfoSuccess: false,
  pResetLoading: false,
  pResetError: false,
  pResetErrorMessage: '',
  pResetSuccess: false
}

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_USER_PROFILE:
    return  {...initialState, setInfoLoading: true}
  case types.SET_USER_PROFILE_FAIL:
    return  {...initialState, setInfoError: true, setInfoErrorMessage: action.payload}
  case types.SET_USER_PROFILE_SUCCESS:
    return  {...initialState, setInfoSuccess: true}
  case types.SET_USER_PROFILE_PASSWORD:
    return  {...initialState, pResetLoading: true}
  case types.SET_USER_PROFILE_PASSWORD_FAIL:
    return  {...initialState, pResetError: true, pResetErrorMessage: action.payload}
  case types.SET_USER_PROFILE_PASSWORD_SUCCESS:
    return  {...initialState, pResetSuccess: true}
  case types.RESET_USER_PROFILE:
    return  {...initialState}
  default: 
    return state
  }
}

export default userProfileReducer