import * as types from './types'
import {apicaller} from '../utils/apicaller'
import {clearStore} from '../actions/app_actions'
import config from '../config'

export const userSignin = user => async (dispatch) => {
  dispatch({type: types.USER_SIGNIN}) //Loading

  try {
    //login
    const login = await apicaller({
      url: `${config.backUrl}/v2/login`,
      method: 'post',
      data: user,
    }, dispatch)

    //dispatch information
    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: {
        username: login.username,
        firstname: login.firstname,
        lastname: login.lastname,
        credits: login.credits,
        role: login.role,
      }
    })

  } catch (err) {
    if (err.status === 401) {
      dispatch({type: types.USER_SIGNIN_FAIL, error: 'Nombre de usuario o contraseña inválida'})
    } else {
      dispatch({type: types.USER_SIGNIN_FAIL, error: 'Error desconocido, vuelve a intentarlo'})
    }
  }
}

export const userSignout = () => async (dispatch) => {
  await apicaller({
    url: `${config.backUrl}/v2/logout`,
    method: 'get',
  }, dispatch)
  dispatch(clearStore())
}

export const resetUser = () => (
  {type: types.RESET_USER_LOGIN}
)
