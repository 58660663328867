import React from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {IntlContext} from '../../helpers/IntlContext'

import b4sLogo from '../../assets/img/logo-footer.svg'
import styles from './Footer.module.scss'

const Footer = () => {
  const {locale, switchLanguage} = React.useContext(IntlContext)

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.firstRow}>
          <div className={styles.lang}>
            <i className={`fas fa-language ${styles.langIcon}`}></i>
            <i className={`far fa-angle-down ${styles.arrowIcon}`}></i>
            <select value={locale} onChange={event => switchLanguage(event.target.value)}>
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="de">Deutsch</option>
              <option value="fr">Français</option>
              {/* <option value="ja">Japanese</option> */}
            </select>
          </div>
          <div className={styles.logo}>
            <img alt="logo" src={b4sLogo} />
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.secondRow}>
          <nav>
            <ul className={styles.menu}>
              <li className={styles.menuLink}><Link to="/terms-conditions"><span><FormattedMessage id="components.footer.terms" defaultMessage="Términos y condiciones" /></span></Link></li>
              <li className={styles.menuLink}><Link to="/privacy-policy"><span><FormattedMessage id="components.footer.privacy" defaultMessage="Política de privacidad" /></span></Link></li>
              <li className={styles.menuLink}><Link to="/legal-notice"><span><FormattedMessage id="components.footer.legal" defaultMessage="Aviso legal" /></span></Link></li>
              <li className={styles.menuLink}><Link to="/faq"><span><FormattedMessage id="components.footer.faq" defaultMessage="FAQ" /></span></Link></li>
              <li className={styles.menuLink}><Link to="/cookies-policy"><span><FormattedMessage id="components.footer.cookies" defaultMessage="Política de cookies" /></span></Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}


export default Footer
