import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {connect} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {userSignout} from '../../actions/auth_actions'
import Button from '../Button'
import DisconnectMenu from './components/DisconnectMenu'
import b4sTypo from '../../assets/img/logo-typo.svg'
import b4sLogo from '../../assets/img/logo-b4s.svg'
import {AnimatePresence} from 'framer-motion'


const Header = (props) => {
  const location = useLocation()
  const [userMenu, setUserMenu] = useState(false)

  const privateArea = () => (
    <div className="flex">
      <div className="flex items-center">
        <p className="text-b4s-blue-3 text-sm">
          <span className="hidden sm:inline-block">
            <FormattedMessage 
              id="components.header.hellouser" 
              defaultMessage="Hola <b>{displayname}</b>, tienes"
              values={{
                b: chunks => <span className="font-bold">{chunks}</span>,
                displayname: props.displayname
              }}
            />
          </span>
          <span className="inline-block sm:hidden">
            <FormattedMessage 
              id="components.header.youhave" 
              defaultMessage="Tienes"
            />
          </span>
        </p>
        <p className="text-b4s-blue-3 text-sm pl-1 pr-2">{props.credits}</p>
        <i className={'fas fa-gem bg-clip-text text-transparent bg-gradient-to-r from-b4s-pink-6 to-b4s-pink-7 text-sm'}></i>
      </div>

      <div className="">
        <i onClick={() => setUserMenu(!userMenu)} className="fas fa-user-circle pl-5 text-3xl cursor-pointer"></i>
        <AnimatePresence>
          <ShowDisconnectMenu />
        </AnimatePresence>
      </div>
    </div>
  )

  const disconnectArea = () => (
    <div className="flex">
      <div className="flex items-center">
        <p className="text-b4s-blue-3 text-sm">
          <span className="hidden sm:inline-block">
            <FormattedMessage 
              id="components.header.hello" 
              defaultMessage="Hola "
            />
          </span>
          <span className="font-bold"> {props.displayname}</span>
        </p>
      </div>

      <div className="">
        <i onClick={() => setUserMenu(!userMenu)} className={'fas fa-user-circle pl-5 text-3xl cursor-pointer'}></i>
        <AnimatePresence>
          <ShowDisconnectMenu />
        </AnimatePresence>
      </div>
    </div>
  )

  const guestArea = () => (
    <div className="flex space-x-4">
      <Link to="/login"><Button small light className="uppercase font-semibold" ><FormattedMessage id="pages.home.header.login" defaultMessage="Login" /></Button></Link>
      <Link to="/sign-up"><Button small light className="uppercase font-semibold" ><FormattedMessage id="pages.home.header.register" defaultMessage="Registrarme" /></Button></Link>
    </div>
  )

  const ShowDisconnectMenu = () => {
    if (userMenu) {
      return (
        <DisconnectMenu key="userMenu" userSignout={() => props.userSignout()} />
      )
    }

    return null
  }

  return (
    <div className="py-5 md:pt-12 flex justify-between items-center">
      {!['/legal-pages'].includes(location.pathname) && 
      <div className="">
        <Link className="md:hidden" to="/"><img className="h-10" alt="logo" src={b4sLogo} /></Link>
        <Link className="hidden md:block" to="/"><img className="h-16" alt="logo" src={b4sTypo} /></Link>
      </div>
      }
      {['active', 'inactive'].includes(props.role) && privateArea()}
      {['producer', 'affiliate'].includes(props.role) && disconnectArea()}
      {['guest'].includes(props.role) && !['/sign-up'].includes(location.pathname) && guestArea()}
    </div>
  )
}

const mapStateToProps = ({user}) => ({
  credits: user.credits,
  displayname: user.displayname,
  token: user.token,
  role: user.role
})


export default connect(mapStateToProps, {userSignout})(Header)
