import * as types from './types'
import {apicaller} from '../utils/apicaller'
import config from '../config'

export const getBeats = () => async (dispatch) => {
  dispatch({type: types.GET_BEATS})

  try {
    const data = await apicaller({
      url: `${config.backUrl}/v2/beats`
    }, dispatch)

    dispatch({type: types.GET_BEATS_SUCCESS, payload:data})
  } catch (err) {
    dispatch({type: types.GET_BEATS_FAIL})
  }
}

export const addBeatReproduction = (beatid) => async (dispatch) => {  
  try {
    await apicaller({
      url: `${config.backUrl}/v2/beat/record/play`,
      method: 'POST',
      data: {beatid}
    }, dispatch)

  } catch (e) {
  }
}
  

export const rememberPassword = (details) => async (dispatch) => {
  dispatch({type: types.REMEMBER_PASSWORD})

  try {
    await apicaller({
      url: `${config.backUrl}/v2/remember-password`,
      method: 'post',
      data: details,
    }, dispatch)

    dispatch({type: types.REMEMBER_PASSWORD_SUCCESS})

  } catch (err) {
    dispatch({type: types.REMEMBER_PASSWORD_FAIL, error: 'Usuario o correo electrónico inválido'})
  }
}


export const resetPasswordAction = (details) => async (dispatch) => {
  dispatch({type: types.RESET_PASSWORD})
  
  try {
    await apicaller({
      url: `${config.backUrl}/v2/reset-password`,
      method: 'post',
      data: details,
    }, dispatch)
  
    dispatch({type: types.RESET_PASSWORD_SUCCESS})
  
  } catch (err) {
    dispatch({type: types.RESET_PASSWORD_FAIL, error: 'Usuario o correo electrónico inválido'})
  }
}
